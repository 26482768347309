
import { defineComponent, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import EmployeeModal from "@/components/modals/forms/employee/EmployeeModal.vue";
import { Form, Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "association_contract",
  components: {
    Datatable,
    EmployeeModal,
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    const entityInfoID = 5;
    return { entityInfoID };
  },

  data() {
    return {
      contract: {
        tranche_id: "",
        entity_id: "",
        dob: "",
        nid: "",
        passport: "",
        driving_license: "",
        mobile: "",
        email: "",
        gender: "",
        religion: "",
        present_address: "",
        present_district: "",
        permanent_address: "",
        permanent_district: "",
      },
      tableHeader: [
        {
          name: "Action",
          key: "actions",
          sortable: true,
          width: "150px",
        },
        {
          name: "SL",
          key: "sl",
          sortable: true,
        },
        {
          name: "Training Partner",
          key: "training_partner",
          sortable: true,
        },
        {
          name: "Tranche",
          key: "tranche",
          sortable: true,
        },
        {
          name: "Contract Number",
          key: "contract_number",
          sortable: true,
        },
        {
          name: "Contract Name",
          key: "contract_name",
          sortable: false,
        },
        {
          name: "Sign Date",
          key: "sign_date",
          sortable: false,
          width: "100px",
        },
        {
          name: "Start",
          key: "start",
          sortable: false,
        },
        {
          name: "End",
          key: "end",
          sortable: false,
        },
        {
          name: "Training Target",
          key: "training_target",
          sortable: false,
        },
        {
          name: "Contract Amount",
          key: "contract_amount",
          sortable: false,
        },
        {
          name: "Attachment",
          key: "attachment",
          sortable: false,
        },
      ],
      tableData: [
        {
          id: 1,
          training_partner: "BITM",
          tranche: "Trance-1",
          contract_number: "",
          contract_name: "Zohirul Alam Tiemoon",
          sign_date: "2019-02-25",
          start: "",
          end: "",
          training_target: "",
          contract_amount: "",
          attachment: "",
        },
        {
          id: 2,
          training_partner: "BITM",
          tranche: "Tranche-2",
          contract_number: "",
          contract_name: "Abdul Alim",
          sign_date: "2019-10-22",
          start: "",
          end: "",
          training_target: "",
          contract_amount: "",
          attachment: "",
        },
        {
          id: 3,
          training_partner: "BITM",
          tranche: "Tranche-2",
          contract_number: "",
          contract_name: "Robin AB",
          sign_date: "2020-04-20",
          start: "",
          end: "",
          training_target: "",
          contract_amount: "",
          attachment: "",
        },
        {
          id: 4,
          training_partner: "BIIT",
          tranche: "Tranche-3",
          contract_number: "",
          contract_name: "Nur Alam",
          sign_date: "2019-10-22",
          start: "",
          end: "",
          training_target: "",
          contract_amount: "",
          attachment: "",
        },
        {
          id: 5,
          training_partner: "NTI",
          tranche: "Tranche-2",
          contract_number: "",
          contract_name: "Md Maruf",
          sign_date: "2019-02-23",
          start: "",
          end: "",
          training_target: "",
          contract_amount: "",
          attachment: "",
        },
        {
          id: 6,
          training_partner: "BITM",
          tranche: "Tranche-1",
          contract_number: "",
          contract_name: "Abdur Rahim",
          sign_date: "2021-02-25",
          start: "",
          end: "",
          training_target: "",
          contract_amount: "",
          attachment: "",
        },
        {
          id: 7,
          training_partner: "BIIT",
          tranche: "Tranche-2",
          contract_number: "",
          contract_name: "Md Taybur",
          sign_date: "2021-10-21",
          start: "",
          end: "",
          training_target: "",
          contract_amount: "",
          attachment: "",
        },
        {
          id: 8,
          training_partner: "NTI",
          tranche: "Tranche-3",
          contract_number: "",
          contract_name: "Zohirul Alam Tiemoon",
          sign_date: "2021-10-25",
          start: "",
          end: "",
          training_target: "",
          contract_amount: "",
          attachment: "",
        },
        {
          id: 9,
          training_partner: "BITM",
          tranche: "Tranche-2",
          contract_number: "",
          contract_name: "Abu Sayam",
          sign_date: "2021-10-25",
          start: "",
          end: "",
          training_target: "",
          contract_amount: "",
          attachment: "",
        },
        {
          id: 10,
          training_partner: "NTI",
          tranche: "Tranche-2",
          contract_number: "",
          contract_name: "Zohirul Alam Tiemoon",
          sign_date: "2021-10-25",
          start: "",
          end: "",
          training_target: "",
          contract_amount: "",
          attachment: "",
        },
        {
          id: 11,
          training_partner: "BITM",
          tranche: "Tranche-1",
          contract_number: "",
          contract_name: "Md Kabir",
          sign_date: "2021-10-25",
          start: "",
          end: "",
          training_target: "",
          contract_amount: "",
          attachment: "",
        },
        {
          id: 12,
          training_partner: "BIIT",
          tranche: "Tranche-2",
          contract_number: "",
          contract_name: "Zohirul Alam Tiemoon",
          sign_date: "2021-10-25",
          start: "",
          end: "",
          training_target: "",
          contract_amount: "",
          attachment: "",
        },
      ],
      lists: [],
      search: "",
      data: {},
      tabIndex: ref(0),
      entityInfos: [],
      entityTypes: [],
    };
  },
  async created() {
    await this.getEntity();
    await this.getTypes();
    Object.assign(this.tableData, this.lists);
  },
  methods: {
    setActiveTab(event) {
      this.tabIndex = parseInt(event.target.getAttribute("data-tab-index"));
    },
    async getEntity() {
      await ApiService.get("entity/infos/" + this.entityInfoID)
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    // searchItems() {
    //   if (this.search !== "") {
    //     let results: never[] = [];
    //     for (let j = 0; j < this.tableData.length; j++) {
    //       if (this.searchingFunc(this.tableData[j], this.search)) {
    //         results.push(this.tableData[j]);
    //       }
    //     }
    //     this.tableData.splice(0, this.tableData.length, ...results);
    //   } else {
    //     Object.assign(this.tableData, this.lists);
    //   }
    // },

    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    },

    edit(data) {
      this.emitter.emit("edit-modal-data", data);
    },

    add() {
      this.$router.push({
        name: "entity employee",
        params: {
          id: 1,
        },
      });
    },

    view(id) {
      this.$router.push({
        name: "entityEmployeeProfiles",
        params: {
          entity: this.entityInfoID,
          id: id,
        },
      });
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("employee/newProfile/" + `${id}`)
            .then((response) => {
              this.emitter.emit("infos-updated", true);
              Swal.fire("Deleted!", response.data.message, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
